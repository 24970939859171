.App {
  text-align: left;
}

.App-header {
  /*background-color: #ffffff;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}
